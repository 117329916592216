.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch.is-success span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .switch.is-success span:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .switch.is-success span {
    background-color: #48c774;
  }
  
  input:checked + .switch.is-success span:before {
    transform: translateX(14px);
  }
  
  .switch.is-danger span {
    background-color: #ff3860;
  }
  
  input:checked + .switch.is-danger span:before {
    background-color: #ff3860;
  }
  
  .switch.is-info span {
    background-color: #209cee;
  }
  
  input:checked + .switch.is-info span:before {
    background-color: #209cee;
  }
  
  .switch.is-warning span {
    background-color: #ffdd57;
  }
  
  input:checked + .switch.is-warning span:before {
    background-color: #ffdd57;
  }
  
  .switch.is-primary span {
    background-color: #00d1b2;
  }
  
  input:checked + .switch.is-primary span:before {
    background-color: #00d1b2;
  }
  