/* ModalStyle.css */

.modal-content.custom-modal {
    max-width: 400px;
    margin: auto;
    border-radius: 8px;
    background-color: #fff;
    padding: 20px;
    position: relative;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    opacity: 1;
}

.image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

.button {
    margin-top: 10px;
}
