.navbar-brand .navbar-logo {
    max-width: 1000px; /* Asegura el tamaño máximo */
    width: 180px; /* Ancho fijo */
    height: 60px; /* Alto fijo */
    max-height: none;
    object-fit: contain; /* Ajusta la imagen sin distorsionarla */
  }
  

  
  /* Ajustes adicionales si es necesario */
  .navbar {
    z-index: 10; /* Asegura que el navbar se muestre por encima del contenido */
  }
  .navbar-burger {
    border: none;
    background: none;
    cursor: pointer;
    display: flex !important; /* Asegura que el botón de hamburguesa esté siempre visible */
    align-items: center; /* Centra el contenido verticalmente */
    padding: 0; /* Elimina el padding para evitar un fondo azul */
  }
  
  .navbar-burger:focus,
  .navbar-burger:active {
    outline: none; /* Elimina el borde de enfoque predeterminado */
    box-shadow: none; /* Elimina cualquier sombra que pueda aplicarse al botón */
    background: none; /* Asegúrate de que no tenga fondo al ser clickeado */
  }
  
  .navbar-burger .icon {
    font-size: 1.5rem;
    color: black; /* Asegúrate de que el icono sea visible */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Ajustes adicionales si es necesario */
  .navbar {
    z-index: 10; /* Asegura que el navbar se muestre por encima del contenido */
  }