/* ModalStyle.css */

.modal-content {
    max-height: 700px !important;
    max-width: 2000px !important; 
    width: 1000px !important;/* Ajusta la altura máxima del modal */
    overflow:auto;
    
  }
  
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    
  }
  
  .modal-body iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
  
  .field {
    margin-bottom: 0.1rem !important;
  }
  .modal-title {
    color: black;
    font-style: bold;
    font-size: 1.2rem;
  }
  
 .descripcion{
   margin-top: -30%;
 }


.modal-title.descripcion2{
 margin-top: -35% !important;
}