.preview-box {
    display: flex !important;  
    align-items: center !important;
    justify-content: center !important;
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
    height: 400px !important;
    width: 500px !important;
    overflow: hidden !important;
    background-size: auto !important;
  }
  
  .preview-box img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }
  
  .image-upload .box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 128px !important;
    width: 128px !important;
  }
.columns{
    height: 500px !important;
    min-height: 100px !important    ;
}

.dropdown {
  position: relative !important;
}

.dropdown-menu {
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  background-color: white !important;
  border: 1px solid #dbdbdb !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  z-index: 1000 !important;
  max-height: 200px !important;
  overflow-y: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
}

.dropdown-item {
  padding: 10px 20px !important;
  cursor: pointer !important;
}

.dropdown-item:hover {
  background-color: #f5f5f5 !important;
}
