.field.has-addons {
    margin-bottom: 1rem !important;
  }
  
  .control.has-icons-left .input {
    padding-left: 2.5em !important;
  }
  
  .icon.is-left {
    left: 0.75em !important;
  }
  
  .dropdown-menu {
    width: 100% !important;
  }
  
  .dropdown-content {
    border-radius: 4px !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
  }
  
  .dropdown-item.is-active {
    background-color: #3273dc !important;
    color: white !important;
  }
  
  .dropdown-item:hover {
    background-color: #3273dc !important;
    color: white !important;
  }
  .dropdown-trigger .button{
    margin-top: 0px;
  }
  