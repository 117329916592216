/* Layout.css */


.custom-column {
  width: 90%; /* Ajusta el ancho */
  height: 2500px; /* Ajusta la altura */
}

.columns {
    margin-top: 3.5rem; /* Ajusta el margen superior según sea necesario */
    min-height: 100vh;
  }
  
  .sidebar-container {
    position: fixed;
    top: 3.5rem; /* Ajusta según la altura de tu navbar */
    left: 0;
    width: 20%;
    height: calc(100% - 3.5rem); /* Ajusta la altura restando la altura del navbar */
    background-color: white; /* Ajusta el fondo de la sidebar */
    padding-top: 1rem; /* Ajusta el padding según sea necesario */
    box-shadow: 2px 0 5px #0367A6;
  }
  
  .is-offset-2 {
    margin-left: 20%; /* Asegúrate de que el contenido principal no se superponga */
  }
  
  .column.is-10 {
    padding-left: 1rem; /* Agrega un poco de espacio entre la sidebar y el contenido principal */
    padding-top: 2rem; /* Ajusta el padding superior si es necesario */
  }
  
  /* Ajustes adicionales para el Navbar si es necesario */
  .navbar {
    z-index: 10; /* Asegura que el navbar se muestre por encima del contenido */
    position: fixed;
    width: 100%;
    top: 0;
  }
 