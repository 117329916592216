/* LogInStyle.css */
html, body {
    height: 100%;
    margin: 0;
    
  }
.hero-body{
    background-color: #f5f5f5b6 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
  .custom-box {
    padding: 2rem !important; /* Asegura prioridad sobre Bulma */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    background-color: #ffffff !important;
    border-radius: 8px !important;
  }
  
  .logo-box {
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 1.5rem !important;
  }
  
  .logo-image {
    max-width: 70% !important;
    height: auto !important;
  }
  
  .field {
    margin-bottom: 1rem !important;
  }
  
  .field .label {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
  
  .field .control {
    position: relative !important;
  }
  
  .field .control .input {
    padding-right: 3rem !important; /* Aumenta el espacio para el ícono */
  }
  
  .field .control .password-icon {
    position: absolute !important;
    left: 90%;
    right: 0.5rem !important; /* Ajusta la posición del icono */
    top: 50% !important;
    transform: translateY(-50%) !important;
    cursor: pointer !important;
    background: #fff !important; /* Fondo blanco para el ícono */
    border: 1px solid #ddd !important; /* Borde izquierdo */
    padding: 0.25rem !important; /* Espaciado alrededor del ícono */
    border-radius: 0 0.25rem 0.25rem 0 !important; /* Bordes redondeados a la derecha */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    pointer-events: auto !important; /* Asegura que el ícono sea clickeable */
    box-shadow: #0367A6 !important;; 
  }
  
  .icon {
    color: #0367A6 !important; /* Color del ícono */
  }
  
  .button.is-primary {
    background-color: #0367A6 !important;
    color: white !important;
  }
  
  .button.is-primary:hover {
    background-color: #3179a6 !important;
  }
  
  .field.has-text-centered {
    margin-top: 1rem !important;
  }
  
  .field.has-text-centered a {
    color: #0367A6 !important;
  }
  
  .field.has-text-centered a:hover {
    color: #3179a6 !important;
    text-decoration: underline !important;
  }
 