/* Sidebar.css */
.sidebar {
    width: 100%; /* Asegúrate de que la sidebar ocupe todo el ancho de la columna */
    overflow-y: auto;

  }
  
  .menu-label {
    font-size: 0.95rem;
    font-weight: bold;
    margin-top: 1rem;
    color: #0367a6 !important;

  }
  
  .menu-list a {
  font-size: 1rem !important;
    padding: 10px 0 ; 
    display: block !important;
    color: black !important;
    background-color: white !important;
    
  }
  
  .menu-list a:hover {
    background-color: #f2b90f !important;
    color: black !important;
    font-weight: bold;
    
  }
  .menu-list .is-active {
    background-color: #f2b90f !important; /* Color dorado */
    color: black !important;
  }
  
  .menu-list .submenu .is-active {
    background-color: #f2b90f !important; /* Color dorado */
    color: black !important;

  }
  