/* Welcome.css */
.cards-container {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
  
.chart-buttons .button{
  background-color: red !important;
}
canvas{
  border: 1px solid black;
  background-color: white;
}
.bar-chart-container h3{
  font-size: "900px" !important;
}