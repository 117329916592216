.user-profile {
    display: flex !important;
    align-items: center !important;
    padding: 10px !important;
    background-color:  white!important;
    color: white !important; /* Cambia a negro para asegurar visibilidad */
    margin-bottom: 20px !important;
    border-radius: 5px !important;
    margin-top: 20px !important;
    border: 2px solid #0367a6 !important; /* Asegúrate de que el borde esté definido */
  }
  
  .user-image {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    margin-right: 10px !important;
  }
  
  .user-info {
    display: flex !important;
    flex-direction: column !important;
  }
  
  .user-info h3 {
    margin: 0 !important;
    font-size: 1.3em !important;
    color: #0367a6 !important;
  }
  
  .user-info p {
    margin: 0 !important;
    font-size: 0.9em !important;
    color: #0367a6 !important;
  }
  .status {
    display: flex !important;
    align-items: center !important;
    color: green !important; /* Color para el texto "En línea" */
    font-size: 0.8em !important; /* Tamaño de fuente para el texto "En línea" */
    margin-top: 5px !important; /* Espaciado superior para separar del rol */
  }
  
  .status-indicator {
    width: 8px !important;
    height: 8px !important;
    background-color: green !important;
    border-radius: 50% !important;
    margin-right: 5px !important;
  }